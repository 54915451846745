import { Home } from "./Componente/Home";

function App() { 

  return (
    <div  className=''>
      <Home></Home>
		</div>
  );
}

export default App;
